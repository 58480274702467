<template>
  <div
    class="product-create create-form"
    v-if="getPermission('part:create')"
    style="height: calc(100vh - 140px)"
  >
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-container fluid class="white-background main-create-page">
          <div class="p-5 pb-0">
            <v-row>
              <v-col cols="6" class="pt-0 px-0">
                <h1 class="form-title d-flex font-size-20 align-center">
                  Tagging Label Details 
                  <span class="ml-4">
                    <v-chip
                      label
                      color="chip-custom-blue"
                      outlined
                      class="text-white"
                      style="font-size: 21px !important; font-weight: 700"
                      >{{tagging.barcode}}</v-chip
                    >
                  </span>
                </h1>
              </v-col>
              <v-col cols="6" class="pt-0 text-right">
                <v-btn
                  :disabled="!formValid || formLoading"
                  :loading="formLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  :to="
                  getDefaultRoute('tagging.update', {
                    param: { id: taggingId },
                  })"
                  ><v-icon small>edit</v-icon> Edit
                </v-btn>
                <v-btn
                  :disabled="!formValid || formLoading"
                  :loading="formLoading"
                  class="mx-2 custom-bold-button text-white"
                  color="cyan"
                  v-on:click="duplicateProduct('duplicate')"
                  ><v-icon small>content_copy</v-icon> Duplicate
                </v-btn>
                <v-btn
                  :disabled="!formValid || formLoading"
                  :loading="formLoading"
                  class="mx-2 custom-bold-button custom-btn-color white--text"
                  v-on:click="dialog = !dialog"
                  ><v-icon small>print</v-icon> Print
                </v-btn>
                <v-btn
                  v-on:click="goBack"
                  :disabled="formLoading"
                  class="custom-grey-border custom-bold-button"
                  style="background-color: #f5f5f5"
                  outlined
                  ><v-icon small>arrow_back</v-icon> Back
                </v-btn>
              </v-col>
              <v-col md="12" class="my-auto py-0">
                <v-row>
                  <v-col md="8"
                    ><v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col md="12">
                            <v-row class="align-center">
                              <v-col md="6" class="my-auto py-0">
                                <v-flex class="">
                                  <img
                                    max-width="250"
                                    width="250"
                                    src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/IMPROVED/files/barcodes/A-000018.png"
                                  />
                                  <p class="font-weight-700">3985734895</p>
                                </v-flex>
                              </v-col>
                              <v-col md="6" class="my-auto py-0"></v-col>
                              <v-col md="5" class="my-auto py-0">
                                <label
                                  for="Equipment-Type"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >Serial No.</label
                                >
                              </v-col>
                              <v-col md="6" class="my-auto py-0">
                                <p v-if='tagging.serial_no'>
                                  <v-chip
                                    label
                                    color="chip-custom-blue"
                                    outlined
                                    small
                                    class="text-white"
                                    style="
                                      font-size: 13px !important;
                                      font-weight: 700;
                                    "
                                    >{{ tagging.serial_no }}</v-chip
                                  >
                                </p>
                                <p v-else>No serial number</p>
                              </v-col>
                              <v-col md="5" class="my-auto py-0">
                                <label
                                  for="phone-number"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >Customer Name</label
                                >
                              </v-col>
                              <v-col md="6" class="my-auto py-0">
                                <p class="font-weight-600 font-size-18">
                                  <ShowValue :object="tagging" object-key="customer_name" label="Customer Name"></ShowValue>
                                </p>
                              </v-col>
                              <v-col md="5" class="my-auto py-0">
                                <label
                                  class="pl-2 font-weight-700 font-size-16 width-100"
                                  >Received Date</label
                                >
                              </v-col>
                              <v-col md="6" class="my-auto py-0">
                                <p class="font-weight-600 font-size-18">
                                  <ShowValue :object="tagging" object-key="existing_date" label="Date"></ShowValue>
                                </p>
                              </v-col>
                              <v-col md="5" class="my-auto py-0">
                                <label
                                  for="address-line-1"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >Job Order No.</label
                                >
                              </v-col>
                              <v-col md="6" class="my-auto py-0">
                                <p class="font-weight-600 font-size-18">
                                  <ShowValue :object="tagging" object-key="job_order_no" label="Job order No."></ShowValue>
                                </p>
                              </v-col>
                              <v-col md="5" class="my-auto py-0">
                                <label
                                  for="address-line-1"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >Quotation Number</label
                                >
                              </v-col>
                              <v-col md="6" class="my-auto py-0">
                                <p class="font-weight-600 font-size-18">
                                  <ShowValue :object="tagging" object-key="quotation_number" label="Quotation Number"></ShowValue>
                                </p>
                              </v-col>
                              <v-col md="5" class="my-auto py-0">
                                <label
                                  for="equipment-type"
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >Equipment type</label
                                >
                              </v-col>
                              <v-col md="6" class="my-auto py-0">
                                <p class="font-weight-600 font-size-18">
                                  <ShowValue :object="tagging" object-key="equipement_type" label="Equipment type"></ShowValue>
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col md="4">
                    <v-row>
                      <v-col md="12" class="mt-7">
                        <v-row class="" style="margin-top:20px!important;">
                            <v-col md="6" class="my-auto py-0">
                              <label
                                for="added-at"
                                class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                >Created At</label>
                            </v-col>
                            <v-col md="6" class="my-auto py-0" >
                              <p class="font-weight-600 font-size-18" style="color:rgba(0, 0, 0, 0.6);margin-top:10px;">
                                <ShowValue :object="tagging" object-key="added_at" label="Added At"></ShowValue>
                              </p>
                            </v-col>
                            <v-col md="6" class="my-auto py-0">
                              <label
                                for="added-by"
                                class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                >Created By</label
                              >
                            </v-col>
                            <v-col md="6" class="my-auto py-0">
                              <p class="font-weight-600 font-size-18" style="color:rgba(0, 0, 0, 0.6);margin-top:10px;">
                                <ShowValue :object="tagging" object-key="addedBy" label="Added By"></ShowValue>
                              </p>
                            </v-col>
                            
                            <v-col md="6" class="my-auto py-0">
                              <label
                                for="updated-at"
                                class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                >Updated At</label
                              >
                            </v-col>
                            <v-col md="6" class="my-auto py-0">
                              <p class="font-weight-600 font-size-18" style="color:rgba(0, 0, 0, 0.6);margin-top:10px;">
                                <ShowValue :object="tagging" object-key="updated_at" label="Updated At"></ShowValue>
                              </p>
                            </v-col>
                            <v-col md="6" class="my-auto py-0">
                              <label
                                for="updated-by"
                                class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                >Updated By</label
                              >
                            </v-col>
                            <v-col md="6" class="my-auto py-0">
                              <p class="font-weight-600 font-size-18" style="color:rgba(0, 0, 0, 0.6);margin-top:10px;">
                                <ShowValue :object="tagging" object-key="updatedBy" label="Updated By"></ShowValue>
                              </p>
                            </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <!-- <v-row>
                      <v-col md="12">
                        <v-row class="mt-5">
                          <div class="d-flex justify-content-center">
                            <v-chip
                              label
                              color="chip-custom-blue"
                              outlined
                              small
                              class="text-white p-2"
                              style="
                                font-size: 13px !important;
                                font-weight: 600;
                              "
                              >Logo</v-chip
                            >
                          </div>
                          <v-col md="12" class="my-auto py-0">
                            <img
                              max-width="200"
                              width="200"
                              class="rounded-circle"
                              src="https://fsm-v3.businessthrust.com/media/default/no-image.png"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row> -->
                  </v-col>
                </v-row>
              </v-col>
              <!-- <Overview></Overview> -->
            </v-row>
          </div>
          <Dialog :common-dialog="dialog" :dialogWidth="450">
            <template v-slot:title>
              <span style="font-size: 18px; font-weight: 800"
                >Print Tagging Label</span
              >
            </template>
            <template v-slot:body>
              <div class="mx-4">
                <p style="font-weight: 700; font-size: 16px" class="">
                  Are you sure you want to print?
                </p>
              </div>
              <!-- <v-row class="my-auto py-0 mx-4">
                <v-radio-group v-model="row" row>
                  <v-radio color="cyan" label="Yes" value="yex"></v-radio>
                  <v-radio color="cyan" label="No" value="no"></v-radio>
                </v-radio-group>
              </v-row> -->
            </template>
            <template v-slot:action>
              <v-btn
                :disabled="!formValid || formLoading || pageLoading"
                :loading="formLoading"
                class="mx-2 custom-grey-border custom-bold-button white--text"
                color="red"
                v-on:click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                :disabled="!formValid || formLoading || pageLoading"
                :loading="formLoading"
                class="mx-2 custom-grey-border custom-bold-button white--text"
                color="cyan"
                v-on:click="printLabel"
              >
                Print
              </v-btn>
            </template>
          </Dialog>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProductMixin from "@/core/plugins/product-mixin";
import { ProductBrandEventBus } from "@/core/lib/product.brand.lib";
import Dialog from "@/view/pages/partials/Dialog.vue";
// import Overview from "@/view/pages/tagging/detail/Overview";
import ShowValue from "@/view/components/ShowValue";
import JwtService from "@/core/services/jwt.service";

import {
  POST,
  GET,
  PUT,
  CLEAR_ERROR,
  UPDATE_ERROR,
} from "@/core/services/store/request.module";

export default {
  mixins: [CommonMixin, ProductMixin],
  name: "tagging-create",
  title: "Create Tagging",
  data() {
    return {
      pageLoading:false,
      dialog: false,
      barcode: null,
      barcodeDialog: false,
      updateMode: false,
      barcodeSetting: {
        prefix_string: null,
        next_number: null,
        number_length: null,
      },
      parttCreate: null,
      taggingCreate: null,
      taggingId: null,
      duplicateId: null,
      tagging: {},
    };
  },
  components: {
    // TaggingCreate,
    Dialog,
    ShowValue,
    // Overview
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  methods: {
    printLabel(){
      const _this = this;
      const token = JwtService.getToken();
      //const rows = _this.selectedRows;
      const selected = this.taggingId; //rows.join(',');
      const routeData = _this.$router.resolve({name: 'print.label_preview', query: {type: "tagging",token:token,ids:selected}});
      window.open(routeData.href, '_blank');
      // var divContents = `<h1>h jhg ghg</h1>`;
      // var printWindow = window.open('', '', 'height=400,width=800');
      // printWindow.document.write(`<html><head><title>DIV Contents</title>
      //     <style>
      //     @page {
      //           size: A8 landscape;
      //           margin: 10%;
      //       }
      //     </style>
      //   `);
      // printWindow.document.write('</head><body >');
      // printWindow.document.write(divContents);
      // printWindow.document.write('</body></html>');
      // printWindow.document.close();
      // printWindow.print();
      
    },
    duplicateProduct() {
      this.$router.push(
        this.getDefaultRoute("tagging.create", {
          query: {
            duplicate: this.taggingId,
          },
        })
      );
    },
    updateTagging(param) {
      this.taggingCreate = param;
    },
    getTagging(taggingId) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (taggingId && taggingId > 0) {
          _this.$store
            .dispatch(GET, { url: "tagging/" + taggingId })
            .then((response) => {
              resolve(response.data);
              this.tagging = response.data;
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve({});
        }
      });
    },

    onSubmit() {
      const _this = this;
      if (!_this.$refs.taggingForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let requestType = POST;
      let requestURL = "tagging";

      if (_this.taggingId && _this.taggingId > 0) {
        requestType = PUT;
        requestURL = "part/" + _this.taggingId;
      }

      _this.$store
        .dispatch(requestType, { url: requestURL, data: _this.taggingCreate })
        .then(async (response) => {
          _this.taggingId = response.data ? response.data.id : null;

          _this.$router.push(
            _this.getDefaultRoute("tagging", {
              query: {
                status: "all",
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    loadContent() {
      const _this = this;
      _this
        .getTagging(_this.taggingId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Tagging", route: "part" },
              { title: "Update" },
            ]);

            _this.taggingCreate = {
              name: response.name,
              barcode: response.barcode ? response.barcode : null,
              gds_code: response.gds_code ? response.gds_code : null,
              description: response.description ? response.description : null,
              product_type: response.product_type,
              purchase_cost: response.purchase_cost,
              selling_cost: response.selling_cost,
              unit: response.unit ? response.unit : null,
              initial_stock: response.initial_stock,
              length: response.length,
              weight: response.weight,
              height: response.height,

              length_unit: response.length_unit,
              weight_unit: response.weight_unit,
              height_unit: response.height_unit,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    loadDuplicateContent() {
      const _this = this;
      _this
        .getTagging(_this.duplicateId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.taggingCreate = {
              name: response.name,
              //barcode: this.barcode,
              gds_code: response.gds_code ? response.gds_code : null,
              description: response.description ? response.description : null,
              product_type: response.product_type,
              purchase_cost: response.purchase_cost,
              selling_cost: response.selling_cost,
              unit: response.unit ? response.unit : null,
              initial_stock: response.initial_stock,
              length: response.length,
              weight: response.weight,
              height: response.height,

              length_unit: response.length_unit,
              weight_unit: response.weight_unit,
              height_unit: response.height_unit,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  created() {
    const _this = this;
    _this.taggingId = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
  mounted() {
    const _this = this;
    
    if(_this.taggingId){
      this.getTagging(_this.taggingId);
      this.pageLoading = false;
      //this.contentLoaded = true;
    }

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tagging", route: "tagging" },
      { title: "Detail" },
    ]);

    if (
      _this.$route.name == "admin.part.update" ||
      _this.$route.name == "engineer.part.update"
    ) {
      _this.updateMode = true;

      if (_this.taggingId <= 0) {
        _this.$router.go(-1);
      }

      _this.loadContent();
    }

    if (_this.duplicateId > 0) {
      _this.loadDuplicateContent();
    }

    ProductBrandEventBus.$on("refress-brand", function () {
      this.getTagging(_this.taggingId);
    });
  },
};
</script>
